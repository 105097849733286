/**@jsx h */
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Link, useRouter, useLocation } from 'wouter'

export default ({ }) =>{
    const r = useLocation()
    const [mOpen, setM] = useState(false)

    
    
    

    return(<div className="Header">
        <Link to="/">
            <span className="brand">
                Tyy<span style={{color : 'white', fontWeight : 'bold', fontSize : '150%' }}>k</span>
            </span>
        </Link>
        <div className="wrapper">
            <svg
            onClick={v=>setM(!mOpen)}
            className={`menu ${mOpen ? 'open' : ''}`}
            width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.75" x2="0.75" y2="20" stroke="currentColor" stroke-width="1.5"/>
                <line x1="9.75" x2="9.75" y2="20" stroke="currentColor" stroke-width="1.5"/>
            </svg>
            <nav className={`${mOpen ? 'open' : ''}`}>
                <Link onClick={v=>setM(false)} to="/">
                    <span  className={r[0] == '/' ? 'active' : ''}>
                        Home
                    </span>
                </Link>
                <Link onClick={v=>setM(false)} to="/about">
                    <span  className={r[0] == '/about' ? 'active' : ''}>
                        About
                    </span>
                </Link>
                {/*
                <a onClick={v=>setM(false)} href="https://blog.tyyk.xyz">
                    <span  className={r[0] == '/blog' ? 'active' : ''}>
                        Blog
                    </span>
                </a>
                */}
                
                
                <Link onClick={v=>setM(false)} to="/lab">
                    <span  className={r[0] == '/lab' ? 'active' : ''}>
                        Lab
                    </span>
                </Link>

            </nav> 
        </div>
       
    </div>)
}