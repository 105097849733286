/**@jsx h */
import { h } from 'preact'

import labImg from '../assets/lab.png'

export default function(){
 return <div className="Lab">
  <embed src={labImg} width="256" />
  <br/><br/>
  <h1>
   Lab is not available right now, Sorry
  </h1>
 </div>
}