/**@jsx h */
import { h } from 'preact';
import { useState } from 'preact/hooks';
import {Transition} from 'preact-transition-group'
import { useRoute, Link } from 'wouter'











export default ({ }) =>{
    const [match, params] = useRoute("/");

    const [win, setWin] = useState(false)
    const [activeBox, setActiveBox] = useState(1)

    



    window.addEventListener('click', c=>{
        
        if(!c.target.classList.contains('window') && c.target.tagName != 'TAG' && !c.target?.parentElement?.classList?.contains('window') && !c.target.parentElement?.parentElement?.classList?.contains('window') && !c.target?.parentElement?.parentElement?.parentElement?.classList?.contains('window') ){
            setWin(false)
        }
    })
    return <Transition in={match}> <div className="App">
        <div className={`window ${win ? 'open' : ''}`} >
            <img alt="profile" src='https://avatars1.githubusercontent.com/u/54923746' width="64" />
            <span className="row-2">
                <span>More Info?</span>
            </span>
            <span className="row-3">
                <Link to="/about">
                    <span>
                        <svg style={{ cursor : 'pointer' }} version="1.1" class="has-solid " viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" width="32" height="32" fill="currentColor"><path d="M25.39,25.45a1,1,0,0,0-1.38.29c-1.41,2.16-4,4.81-6.31,5.7s-4.12.57-4.84,0c-.31-.27-1.12-1-.43-3.49.46-1.66,3.32-9.48,4-11.38l-2.18.28c-.69,1.86-3.29,8.84-3.76,10.58-.68,2.49-.34,4.3,1.09,5.56A5.59,5.59,0,0,0,15,34a9.53,9.53,0,0,0,3.45-.7c2.79-1.09,5.72-4.12,7.26-6.47A1,1,0,0,0,25.39,25.45Z" class="clr-i-outline clr-i-outline-path-1"/><path d="M19.3,11a4.5,4.5,0,1,0-4.5-4.5A4.5,4.5,0,0,0,19.3,11Zm0-7a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,19.3,4Z" class="clr-i-outline clr-i-outline-path-2"/><path d="M11.81,15c.06,0,6.27-.82,7.73-1,.65-.1,1.14,0,1.3.15s.21.8-.07,1.68c-.61,1.86-3.69,11-4.59,13.71a8,8,0,0,0,1.29-.38,7.32,7.32,0,0,0,1.15-.6C19.85,25,22.15,18.1,22.67,16.52s.39-2.78-.3-3.6a3.16,3.16,0,0,0-3.08-.83c-1.43.15-7.47.94-7.73,1a1,1,0,0,0,.26,2Z" class="clr-i-outline clr-i-outline-path-3"/><path d="M24.84,26.23a1,1,0,0,0-1.4.29,16.6,16.6,0,0,1-3.51,3.77c-.33.25-1.56,1.2-2.08,1-.36-.11-.15-.82-.08-1.12l.53-1.57c.22-.64,4.05-12,4.47-13.3.62-1.9.35-3.77-2.48-3.32-.77.08-8.58,1.09-8.72,1.1a1,1,0,0,0,.13,2s3-.39,3.33-.42a.88.88,0,0,1,.85.44,2.47,2.47,0,0,1-.07,1.71c-.26,1-4.37,12.58-4.5,13.25a2.78,2.78,0,0,0,1.18,3,5,5,0,0,0,3.08.83h0a8.53,8.53,0,0,0,3.09-.62c2.49-1,5.09-3.66,6.46-5.75A1,1,0,0,0,24.84,26.23Z" class="clr-i-solid clr-i-solid-path-2" style="display:none"/><circle cx="20.75" cy="6" r="4" class="clr-i-solid clr-i-solid-path-1" style="display:none"/></svg>
                    </span> 
                </Link>
               <span>
                <a href="mailto:mma7200@aol.com">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="mdi-email-outline" width="32" height="32" viewBox="0 0 24 24" fill="#00c4ff"><path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"/></svg>
                </a>
               </span>
                <span>
                    <a href="https://twitter.com/tyyk00">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="entypo-twitter" width="32" height="32" fill="#1da1f2"><g><path d="M17.316 6.246c.008.162.011.326.011.488 0 4.99-3.797 10.742-10.74 10.742-2.133 0-4.116-.625-5.787-1.697a7.577 7.577 0 0 0 5.588-1.562 3.779 3.779 0 0 1-3.526-2.621 3.858 3.858 0 0 0 1.705-.065 3.779 3.779 0 0 1-3.028-3.703v-.047a3.766 3.766 0 0 0 1.71.473 3.775 3.775 0 0 1-1.168-5.041 10.716 10.716 0 0 0 7.781 3.945 3.813 3.813 0 0 1-.097-.861 3.773 3.773 0 0 1 3.774-3.773 3.77 3.77 0 0 1 2.756 1.191 7.602 7.602 0 0 0 2.397-.916 3.789 3.789 0 0 1-1.66 2.088 7.55 7.55 0 0 0 2.168-.594 7.623 7.623 0 0 1-1.884 1.953z"/></g></svg>    
                    </a>
                </span>
                
            </span>
        </div>
        <div className='mainWrapper'>
            <p className="b">
                <p className="intro">
                    <code>Hello,</code> I'm <tag onClick={v=>setWin(true)}>Mohammed Amr</tag>.<br/>     
                </p>
                I'm a web designer/developer, open-source lover and i write tutorials sometimes.
            </p>
            <h2>Hot</h2>
            <img src="https://f000.backblazeb2.com/file/bucketer/diamond-small.jpg" width="320" />
            <img src="https://f000.backblazeb2.com/file/bucketer/cubes-small.jpg" width="320" />

        </div>
        {/*
        <div className="smallWrapper">
            <div className="left">
                <span className={`title ${activeBox == 1 ? 'active' : ''}`} onClick={v=>setActiveBox(1)}>
                    Technologies
                </span>

                <span className={`title ${activeBox == 2 ? 'active' : ''}`} onClick={v=>setActiveBox(2)}>
                    Featured
                </span>

                <span className={`title ${activeBox == 3 ? 'active' : ''}`} onClick={v=>setActiveBox(3)}>
                    More
                </span>
            </div>
            <div className="right">
                <div Key="1" className={`box ${activeBox == 1 ? 'active' : ''}`}>
                    <div className="container">
                        <div class="el">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="32" height="32" fill="#e34f26"><path d="M9.032 2l10.005 112.093 44.896 12.401 45.02-12.387 10.015-112.107h-109.936zm89.126 26.539l-.627 7.172-.276 3.289h-52.665000000000006l1.257 14h50.156000000000006l-.336 3.471-3.233 36.119-.238 2.27-28.196 7.749v.002l-.034.018-28.177-7.423-1.913-21.206h13.815000000000001l.979 10.919 15.287 4.081h.043v-.546l15.355-3.875 1.604-17.579h-47.698l-3.383-38.117-.329-3.883h68.939l-.33 3.539z"/></svg>
                        </div>
                        <div class="el">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="32" height="32" fill="currentColor"><path d="M8.76 1l10.055 112.883 45.118 12.58 45.244-12.626 10.063-112.837h-110.48zm89.591 25.862l-3.347 37.605.01.203-.014.467v-.004l-2.378 26.294-.262 2.336-28.36 7.844v.001l-.022.019-28.311-7.888-1.917-21.739h13.883l.985 11.054 15.386 4.17-.004.008v-.002l15.443-4.229 1.632-18.001h-32.282999999999994l-.277-3.043-.631-7.129-.331-3.828h34.748999999999995l1.264-14h-52.926l-.277-3.041-.63-7.131-.332-3.828h69.281l-.331 3.862z"/></svg>
                        </div>
                       
                    </div>
                </div>
                <div Key="2" className={`box ${activeBox == 2 ? 'active' : ''}`}>
                    box 2
                </div>
                <div Key="3" className={`box ${activeBox == 3 ? 'active' : ''}`}>
                    box 3
                </div>
            </div>
        </div>
        */}

    </div>
    </Transition>

}