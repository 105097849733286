/**@jsx h */
import { h } from 'preact'
import { render } from 'preact/compat'
import { Link, Route, Switch } from "wouter";

import './main.scss'



import App from './pages/App'
import About from './pages/About'
import Lab from './pages/Lab'
import Header from './components/Header';
import { Themer } from './components/stsh';




render((
    <div>
        <Themer />
        <Header />

        <div style={{ height : '10vh' }} key="safeDest"></div>
        <Switch>
            <Route path="/">
                <App />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/lab">
                <Lab />
            </Route>

            <Route path="/:rest*">
                <div>404</div>
            </Route>  
        </Switch>
        
    </div>), document.querySelector('#app'))