/**@jsx h */
import { h } from 'preact'
import { Big } from '../components/stsh'


export default ({ }) =>{


    return(
<div className="About">
    <div className="mainWrapper">
        <Big level={3} className="bigHead">So Hi,</Big>
        <br/>
        <span className="pg">
            I'm Mohammed from Egypt,
            I'm {new Date().getFullYear() - 2000} year-old.<br/>
            I study law <a link target="_blank" href="http://www.asu.edu.eg/">@Ain Shams University</a>. I love computers a lot, Especially web.<br/>
            I create tiny libraries for js. 


        </span>
       
    </div>
</div>)
}